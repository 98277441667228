import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISeqrOpsState } from "./seqr-ops.interface";

//initial state
const initialState: ISeqrOpsState = {
  loadingState: {
    loading: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  newUser : false,
  navigationState: {},
};

const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
  },
  setLogin: (state, action: PayloadAction<boolean>) => {
    state.loginState = {
      ...state.loginState,
      isLoggedIn: action.payload,
    };
},
setAsNewUser: (state, action: PayloadAction<boolean>) => {
  state.newUser = action.payload
},
}});

export const { setLoading, setLogin, setAsNewUser } = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;
