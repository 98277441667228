import { useEffect, useState } from 'react';
import './App.css';
import SeqropsGrafanaComponent from './components/grafana.component/grafana';
import { Navigate, Route, Routes } from 'react-router-dom';
import { encryptUtils } from './utils/encryption.utils';
import { ASSET_SUITE_LOGIN_URL, IFRAME_ID, KEYCLOAK_GRANT_TYPES, SESSION_KEY, SESSION_TYPE } from './config/default.config';
import { ITokenDetails, IValidateTokenPayload } from './store/keycloak/asset-keycloak.interface';
import { setLogin } from './store/seqr-ops/seqr-ops.slice';
import { useAppDispatch } from './store';
import { validateToken } from './store/keycloak/asset-keycloak.action';

function App() {

  const dispatch = useAppDispatch();
  const [ready, setReady] = useState<boolean>(false);
  
  useEffect(()=>{
    let tokenDataEncrypted :any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if(tokenData !== null){
      setReady(true);
        let data = tokenData;
          const ITokenDetails : ITokenDetails = { 
            auth_token : data.token?.auth_token,
            refresh_token : data.token?.refresh_token,
            client_id : data.token?.client_id,
            client_secret : data.token?.client_secret,
            username : data.token?.username,
            password : data.token?.password,
          }
          const IValidateTokenPayload : IValidateTokenPayload = {
            token : data.token?.auth_token,
            client_id : data.token?.client_id,
            client_secret : data.token?.client_secret,
            username : data.token?.username,
            password : data.token?.password,
            grant_type : KEYCLOAK_GRANT_TYPES.PASSWORD        
          }
          dispatch(setLogin(data?.token?.isLoggedIn))
          dispatch(validateToken(IValidateTokenPayload, data.token?.refresh_token, ITokenDetails));
    }else{
      let rl = encryptUtils.encryptURL(SESSION_KEY.OPERATIONAL_DASHBOARD_KEY);
      let postMsg = { [SESSION_KEY.REDIRECT_LOCATION_KEY] : rl, type: SESSION_TYPE.USER_LOGIN_TYPE };
      const POST_DOMAIN_DATA = [
        {
          frameId: IFRAME_ID.SUITE_IFRAME_ID,
          url: `${process.env.REACT_APP_APP_SUITE_URL}`,
        }
      ]
      for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
        console.log("cross domain test in qa dashboards ::: ", POST_DOMAIN_DATA[i]);
        const iframe = document.getElementById(
          POST_DOMAIN_DATA[i].frameId
          ) as HTMLIFrameElement;
        iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
      }
      window.location.replace(ASSET_SUITE_LOGIN_URL); 
    }
    },[])

    if(!ready){
      return null;
    }

  return (
    <div>
      <Routes>
        <Route path="/" element={<SeqropsGrafanaComponent/> }></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
  </div>
  );
}

export default App;
