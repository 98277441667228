import { useEffect, useState } from 'react'
import { SEQROPS_OPERATIONAL_DASHBOARD_GRAFANA_LOGIN_URL, SEQROPS_MANAGEMENET_DASHBOARD_GRAFANA_LOGIN_URL, SESSION_KEY, KEYCLOAK_CLIENTS } from '../../config/default.config'
import Navbar from '../navbar.component/navbar'
import { useAppSelector } from '../../store';
import { IRenderDashboard, IUserAuthorizations } from '../../store/keycloak/asset-keycloak.interface';
import { encryptUtils } from '../../utils/encryption.utils';

function SeqropsGrafanaComponent() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak.userAuthorizations);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] = useState<IUserAuthorizations>();
  const [renderDashboard, setRenderDashboard] = useState<IRenderDashboard>({
    operational_dashboard : false,
    management_dashboard : false,
  }); 
  const [token, setToken] = useState("")

  useEffect(()=>{
    if(localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY) !== null ){
      let tokenDataEncrypted :any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
      let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
      if(tokenData !== null){
         if(localStorage.getItem(SESSION_KEY.DOMAIN_KEY) !== null ){
            let domain :any = localStorage.getItem(SESSION_KEY.DOMAIN_KEY);
            let domainData = encryptUtils.decryptURL(domain);
            let renderDashboardData: IRenderDashboard = {
              operational_dashboard : false,
              management_dashboard : false,
            } 
            if(domainData === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD){
              renderDashboardData.management_dashboard = true;
            }else if (domainData === KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD){
              renderDashboardData.operational_dashboard = true;
            }
            setRenderDashboard(renderDashboardData);
          } 
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setToken(tokenData?.token?.auth_token)
      }
    }else{
      setToken("")
    }
  },[])

  const renderOperationalDashboard = () =>{
    return(
      <>
      <iframe
      // src={SEQROPS_OPERATIONAL_DASHBOARD_GRAFANA_LOGIN_URL+"&auth_token="+token}
      src={SEQROPS_OPERATIONAL_DASHBOARD_GRAFANA_LOGIN_URL}
      title="seqrops-operational-dashboard"
      width="100%"
      height="905px"
    ></iframe>
    </>
    )}

  const renderManagementDashboard = () =>{
      return(
        <>
        <iframe
        // src={SEQROPS_MANAGEMENET_DASHBOARD_GRAFANA_LOGIN_URL+"&auth_token="+token}
        src={SEQROPS_MANAGEMENET_DASHBOARD_GRAFANA_LOGIN_URL}
        title="seqrops-management-dashboard"
        width="100%"
        height="905px"
      ></iframe>
      </>
      )}

  return (
    <>
    <div>
        <div className="seqrops-navbar-section">
            <Navbar />
        </div>
        {(userScopesFromLocalStorage !== undefined ? (userScopesFromLocalStorage.operational_dashboard && renderDashboard.operational_dashboard ) : authorozedScopes.operational_dashboard) && renderOperationalDashboard()}    
        {(userScopesFromLocalStorage !== undefined ? (userScopesFromLocalStorage.management_dashboard && renderDashboard.management_dashboard ) : authorozedScopes.management_dashboard) && renderManagementDashboard()}    
    </div>
  </>
  )
}

export default SeqropsGrafanaComponent
