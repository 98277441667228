import { Tooltip } from "@mui/material";
import seqropsFsmBtn from "../../assets/images/fsm_icon.svg";
import seqropsLogo from "../../assets/images/equserv_conworx_logo.svg";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import seqropsLogoutBtn from "../../assets/images/seqrops-exit-btn.svg";
import seqropsDeviceManagementBtn from "../../assets/images/device_management_icon.svg";
import seqropsAssetModelsBtn from "../../assets/images/asset_model_icon.svg";
import seqropsAssetManagementBtn from "../../assets/images/asset_management_icon.svg";
import managementDashboardBtn from "../../assets/images/management_dashboard_icon.svg";
import operationaDashboardBtn from "../../assets/images/operational_dashboard.svg";
import seqropsHomeBtn from "../../assets/images/seqrops_home_btn.svg";
import seqropsMenuBtn from "../../assets/images/seqrops_menu_icon.svg";
import "./navbar.scss";
import { useAppSelector } from "../../store";
import {
  ILogoutTokenPayload,
  IRenderDashboard,
  ITokenDetails,
  IUserAuthorizations,
} from "../../store/keycloak/asset-keycloak.interface";
import { assetKeycloakActions } from "../../store/keycloak/asset-keycloak.action";
import {
  addAuthorizations,
  setTokens,
} from "../../store/keycloak/asset-keycloak.slice";
import { setLogin } from "../../store/seqr-ops/seqr-ops.slice";
import {
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  CLEAR_CLIENT_SCOPES,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../../config/default.config";
import { OverlayPanel } from "primereact/overlaypanel";
import { encryptUtils } from "../../utils/encryption.utils";

function Navbar() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const isLoggedIn = useAppSelector(
    (state) => state.seqrOps.loginState.isLoggedIn
  );
  const [visibleRight, setVisibleRight] = useState(false);
  const dispatch = useDispatch<any>();
  const op = useRef<OverlayPanel>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [renderDashboard, setRenderDashboard] = useState<IRenderDashboard>({
    operational_dashboard: false,
    management_dashboard: false,
  });

  const [dashboadType, setDashboardType] = useState("");

  const handleLogOut = async () => {
    let ILogoutTokenPayload: ILogoutTokenPayload = {
      refresh_token: authorozedScopes.refresh_token,
      client_id: authorozedScopes.client_id,
      client_secret: authorozedScopes.client_secret,
    };
    setVisibleRight(false);
    await assetKeycloakActions.logoutToken(ILogoutTokenPayload, setSuccess);
  };

  const setSuccess = (state: boolean) => {
    const ITokenDetails: ITokenDetails = {
      auth_token: "",
      refresh_token: "",
      client_id: "",
      client_secret: "",
      username: "",
      password: "",
    };
    dispatch(setTokens(ITokenDetails));
    dispatch(setLogin(false));
    dispatch(addAuthorizations(CLEAR_CLIENT_SCOPES));
    localStorage.removeItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    clearAllTokenData();
    if (state) {
    }
  };

  const handleDashboardClick = (domain: string) => {
    let domainToPass = encryptUtils.encryptURL(domain);
    localStorage.removeItem(SESSION_KEY.DOMAIN_KEY);
    localStorage.setItem(SESSION_KEY.DOMAIN_KEY, domainToPass);
  };

  const handleHomeClick = (domain: string) => {
    let postMsg = {
      type: SESSION_TYPE.DOMAIN_PASS_TYPE,
      domain: encryptUtils.encryptURL(domain),
    };
    const iframe = document.getElementById(
      IFRAME_ID.SUITE_IFRAME_ID
    ) as HTMLIFrameElement;
    iframe.contentWindow?.postMessage(
      postMsg,
      `${process.env.REACT_APP_APP_SUITE_URL}`
    );
  };

  const clearAllTokenData = () => {
    let postMsg = {
      [SESSION_KEY.CLEAR_ALL_TOKEN_DATA_KEY]: "cl3ar",
      type: SESSION_TYPE.USER_LOGOUT_TYPE,
    };
    const POST_DOMAIN_DATA = [
      {
        frameId: IFRAME_ID.SUITE_IFRAME_ID,
        url: `${process.env.REACT_APP_APP_SUITE_URL}`,
      },
    ];
    for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
      console.log("cross domain test in qa models ::: ", POST_DOMAIN_DATA[i]);
      const iframe = document.getElementById(
        POST_DOMAIN_DATA[i].frameId
      ) as HTMLIFrameElement;
      iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
    }
    window.location.href = ASSET_SUITE_LOGIN_URL;
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const confirmLogout = () => {
    setShowDialog(true);
  };

  const footer = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleLogOut}
        autoFocus
      />
    </div>
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dashboardType = urlParams.get("dashboardType");
    if (dashboardType !== null) {
      if (dashboardType === KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD) {
        setDashboardType("Operations Dashboard");
      } else if (dashboardType === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) {
        setDashboardType("Management Dashboard");
      }
    }

    let tokenDataEncrypted: any = localStorage.getItem(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if (tokenData !== null) {
      setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);

      if (localStorage.getItem(SESSION_KEY.DOMAIN_KEY) !== null) {
        let domain: any = localStorage.getItem(SESSION_KEY.DOMAIN_KEY);
        let domainData = encryptUtils.decryptURL(domain);
        let renderDashboardData: IRenderDashboard = {
          operational_dashboard: false,
          management_dashboard: false,
        };
        if (domainData === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) {
          renderDashboardData.management_dashboard = true;
        } else if (domainData === KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD) {
          renderDashboardData.operational_dashboard = true;
        }
        setRenderDashboard(renderDashboardData);
      }
    }
  }, []);

  return (
    <>
      <div
        className="seqrops-navbar-wrapper"
        onMouseLeave={(e) => {
          op.current && op.current?.hide();
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <div className="col-md-3 seqrops-logo-section">
                <img src={seqropsLogo} alt="seqrops logo" />
                <span
                  className={
                    dashboadType === "Management Dashboard"
                      ? "management-dashboard-title-width"
                      : ""
                  }
                >
                  {dashboadType}
                </span>
              </div>
              <div className="seqrops-user-section">
                {/* <Tooltip title="settings">
                <button className="seqrops-settings">
                  <img src={seqropsSettingsIcon} alt="seqrops settings logo" />
                </button>
              </Tooltip> */}
                <Tooltip title="menu">
                  <button className="seqrops-settings">
                    <img
                      src={seqropsMenuBtn}
                      alt="seqrops settings logo"
                      onClick={(e) => op.current && op.current?.toggle(e)}
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <OverlayPanel ref={op}>
          <div className="d-flex align-items-center header-sidebar">
            <div className="me-auto">
              <h4>{authorozedScopes?.username}</h4>
            </div>
            <div className="logout-section">
              <span>Logout</span>
              <img
                src={seqropsLogoutBtn}
                alt="Log out"
                className="img-fluid asset-logout"
                onClick={() => {
                  confirmLogout();
                }}
              />
            </div>
          </div>
          <div className="seqrops-asset-apps">
            <div className="row seqrops-asset-apps-row">
              {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.asset_suite && (
                  <div
                    className="col target-app-container"
                    onClick={() => {
                      handleHomeClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD);
                    }}
                  >
                    <a href={ASSET_URLS.APP_SUITE_URL}>
                      <div className="div">
                        <img
                          src={seqropsHomeBtn}
                          alt="suite"
                          className="img-fluid asset-images"
                        />
                        <span>Home</span>
                      </div>
                    </a>
                  </div>
                )}
              {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.asset_models && (
                  <div className="col target-app-container">
                    <a href={ASSET_URLS.ASSET_MODELS_URL}>
                      <div className="div">
                        <img
                          src={seqropsAssetModelsBtn}
                          alt="models"
                          className="img-fluid asset-images"
                        />
                        <span>Asset Models</span>
                      </div>
                    </a>
                  </div>
                )}
              {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.asset_management && (
                  <div className="col target-app-container">
                    <a href={ASSET_URLS.ASSET_MANAGEMENT_URL}>
                      <div className="div">
                        <img
                          src={seqropsAssetManagementBtn}
                          alt="management"
                          className="img-fluid asset-images"
                        />
                        <span>Asset Management</span>
                      </div>
                    </a>
                  </div>
                )}
            </div>
            <div className="row">
              {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.device_management && (
                  <div className="col target-app-container">
                    <a href={ASSET_URLS.DEVICE_MANAGEMENT_URL}>
                      <div className="div">
                        <img
                          src={seqropsDeviceManagementBtn}
                          alt="device"
                          className="img-fluid asset-images"
                        />
                        <span>Device Management</span>
                      </div>
                    </a>
                  </div>
                )}
              {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.field_service_management && (
                  <div className="col target-app-container">
                    <a href={ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL}>
                      <div className="div">
                        <img
                          src={seqropsFsmBtn}
                          alt="fsm"
                          className="img-fluid asset-images"
                        />
                        <span>Field Service Management</span>
                      </div>
                    </a>
                  </div>
                )}
              {/* {renderDashboard !== undefined &&
                renderDashboard.management_dashboard && (
                  <div className="col target-app-container" onClick={()=>{handleDashboardClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD)}}>
                    <a href={ASSET_URLS.OPERATIONAL_DASHBOARD_URL}>
                      <div className="div">
                        <img
                          src={operationaDashboardBtn}
                          alt="device"
                          className="img-fluid asset-images"
                        />
                        <span>Operations Dashboard</span>
                      </div>
                    </a>
                  </div>
                )} {renderDashboard !== undefined &&
                  renderDashboard.operational_dashboard && (
                    <div className="col target-app-container" onClick={()=>{handleDashboardClick(KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD)}}>
                      <a href={ASSET_URLS.MANAGEMENT_DASHBOARD_URL}>
                        <div className="div">
                          <img
                            src={managementDashboardBtn}
                            alt="device"
                            className="img-fluid asset-images"
                          />
                          <span>Management Dashboard</span>
                        </div>
                      </a>
                    </div>
                  )} */}
             {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.operational_dashboard &&
                renderDashboard.operational_dashboard === false && (
                  <>
                    <div
                      className="col target-app-container"
                      onClick={() => {
                        handleDashboardClick(
                          KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD
                        );
                      }}
                    >
                      <a
                        href={
                          ASSET_URLS.OPERATIONAL_DASHBOARD_URL +
                          "/?dashboardType=operational-dashboard"
                        }
                      >
                        <div className="div">
                          <img
                            src={operationaDashboardBtn}
                            alt="operations"
                            className="img-fluid asset-images"
                          />
                          <span>Operations Dashboard</span>
                        </div>
                      </a>
                    </div>
                  </>
                )}
               {userScopesFromLocalStorage !== undefined &&
                userScopesFromLocalStorage.management_dashboard &&
                renderDashboard.management_dashboard === false && (
                  <>
                    <div
                      className="col target-app-container"
                      onClick={() => {
                        handleDashboardClick(
                          KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD
                        );
                      }}
                    >
                      <a
                        href={
                          ASSET_URLS.MANAGEMENT_DASHBOARD_URL +
                          "/?dashboardType=management-dashboard"
                        }
                      >
                        <div className="div">
                          <img
                            src={managementDashboardBtn}
                            alt="management"
                            className="img-fluid asset-images"
                          />
                          <span>Management Dashboard</span>
                        </div>
                      </a>
                    </div>
                  </>
                )}
            </div>
          </div>
        </OverlayPanel>
        <Dialog
          visible={showDialog}
          onHide={hideDialog}
          header="Confirm Logout"
          footer={footer}
          modal
          style={{ width: "350px" }}
        >
          <div>Are you sure you want to logout?</div>
        </Dialog>
      </div>
    </>
  );
}

export default Navbar;
